import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    companies: [],
    vatId: null,
    // totalCompanies: 0
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_COMPANIES(state, data) {
      state.companies = data
    },
    SET_VAT_ID(state, data) {
      state.vatId = data
    },
    UPDATE_COMPANY(state, company) {
      state.companies.map(c => {
        if (c._id == company._id) {
          c = {...company}
        }
      })
    },
    // SET_TOTAL_COMPANIES(state, totalCompanies) {
    //   state.totalCompanies = totalCompanies
    // },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async createCompany({ commit }, formData) {
      let model = formData.data
      let cb = formData.cb
      let url = `${process.env.VUE_APP_API_URL}/companies`
      try {
        const res = await axiosInstance.post(
          url,
          // `${process.env.VUE_APP_API_URL}/companies/${userId}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Campul a fost adaugat!")
          cb()
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchCompanies({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/companies`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/partnersData/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_COMPANIES', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },

    async  fetchUserVatId({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/companies/vatId`
      try {
        // let url = `${process.env.VUE_APP_API_URL}/companies/partnersData/${userId}`
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_VAT_ID', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    
    async updateCompanyActive({ commit }, formData) {
      let companyId = formData.companyId
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/companies/switchActive/${companyId}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
  },
};
