<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Istoric observatii <b>{{ user ? user.email : '' }}</b></span>
        </v-card-title>
       
        <v-card-text >
          <v-data-table :items="userLogs" :headers="headers" :items-per-page="10" >
            <template v-slot:[`item.createdAt`]="props">
              {{ parseDate(props.item.createdAt) }}
            </template>
            <template v-slot:[`item.createdBy`]="props">
              {{ props.item.createdBy ? props.item.createdBy.name : '' }}
            </template>
          
          </v-data-table>
        
        </v-card-text>
        <v-card-actions class="cardActions">
         
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Inchide</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
export default {
  props: [
    "closeDialog",
    "user",
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    headers: [
      { text: 'Status', value: 'status' },
      { text: 'Comentariu', value: 'comment' },
      { text: 'Adaugat de', value: 'createdBy' },
      { text: 'Data adaugarii', value: 'createdAt' },
    ]
  }),
  watch: {
    user() {
      this.$store.dispatch('fetchUserLogs', this.user._id)
    },
  },
  methods: {
  
    close() {
      this.closeDialog()
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
   
  },
  computed: {
    userLogs() {
      return this.$store.state.userLogs.userLogs
    },
  },
  created() {
    if (this.user) {
      this.$store.dispatch('fetchUserLogs', this.user._id)
    }
  }
};
</script>
