<template>
  <div style="margin: 10px">
    <section-headline>
      Statusuri CRM
    </section-headline>

    <AddButton  :onClick="() => { openCreateDialog() }" caption="Adauga" />
    <v-data-table :items="crmStatuses" :headers="statusHeaders" :search="search" :options.sync="options"
      :server-items-length="totalCRMStatuses" :footer-props="{
      itemsPerPageOptions: [25, 50, 100],
    }">
    
      <template v-slot:[`item.actions`]="props">
        <p class="tableData">
        <div class="flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="openCreateDialog(props.item)" icon color="turquoise" v-bind="attrs" v-on="on">
                <v-icon>mdi-pen</v-icon>
              </v-btn>
            </template>
            <span>Editeaza</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="openDeleteDialog(props.item)" icon color="error" v-bind="attrs" v-on="on">
                <v-icon>mdi-trash-can</v-icon>
              </v-btn>
            </template>
            <span>Sterge</span>
          </v-tooltip>
        </div>
        </p>
      </template>
     
    
    
    </v-data-table>
    <CreateCRMStatus v-if="createDialog" :closeDialog="closeCreateDialog" :editedItem="currentStatus"
      :searchStatuses="searchCRMStatuses" />

    <DeleteDialog v-if="deleteDialog" :item="currentStatus" itemName="statusul CRM" :closeDialog="closeDeleteDialog"
      :deleteAction="deleteStatus" />
  </div>
</template>
<script>
import AddButton from '../components/Buttons/AddButton.vue';
import CreateCRMStatus from '../components/Modals/CreateCRMStatus.vue';
import DeleteDialog from '../components/Modals/DeleteDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
export default {
  components: {
    SectionHeadline,
    AddButton,
    CreateCRMStatus,
    DeleteDialog
  },
  data() {
    return {
      search: '',
      options: {},
      filter: null,
      createDialog: false,
      deleteDialog: false,
      currentStatus: null,
      statusHeaders: [
        {
          text: "Actiuni",
          value: 'actions'
        },
        {
          text: "Status",
          value: 'status'
        },
       
       
      ]
    };
  },
  watch: {
    options() {
      this.searchCRMStatuses()
    },
    search(newVal) {
      this.timeout = setTimeout(() => {
        this.searchCRMStatuses()
      }, 500);
    },
  },
  computed: {
    crmStatuses() {
      return this.$store.state.crm.crmStatuses
    },
    totalCRMStatuses() {
      return this.$store.state.crm.crmStatuses.length
    },
  },
  methods: {
    openCreateDialog(item) {
      this.createDialog = true
      this.currentStatus = item
    },
    closeCreateDialog() {
      this.createDialog = false
      this.currentStatus = null
    },
    openDeleteDialog(item) {
      this.deleteDialog = true
      this.currentStatus = item
    },
    closeDeleteDialog() {
      this.deleteDialog = false
      this.currentStatus = null
    },
    deleteStatus(item) {
      this.$store.dispatch('deleteCRMStatus', {
        model: item,
        cb: () => {
          this.searchCRMStatuses()
        }
      });
    },
 
    searchCRMStatuses() {
      this.$store.dispatch('fetchCRMStatuses', {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        filter: this.filter,
       
      });
    }
  },
  created() {
   
  }
};
</script>
