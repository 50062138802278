<template>
<auth-layout>
  <CRMStatusLayout v-if="isAdmin" />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import CRMStatusLayout from '../Layouts/CRMStatusLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    CRMStatusLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
  },
  watch: {
  },
  methods: {},
  created() {}
};
</script>
