import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    userLogs: [],
  
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_USER_LOGS(state, data) {
      state.userLogs = data
    },
    
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
 
    async createUserLog({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/userLogs`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Observatia a fost adaugata cu succes!");
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchUserLogs({ commit }, id) {
     
      try {
        let url = `${process.env.VUE_APP_API_URL}/userLogs/${id}`
       
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_USER_LOGS', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
