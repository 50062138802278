<template>
  <!-- Desktop Menu -->
  <nav class="bg-primary">
    <v-navigation-drawer v-model="sidebar" app style="width:80%">
      <v-row class="fill-height" no-gutters>
        <v-navigation-drawer mini-variant mini-variant-width="56" permanent color="secondary">
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img :src="$store.state.auth.user.avatar"></v-img>
            </v-list-item-avatar>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense nav>
            <v-list-item :to="'/profile'">
              <v-list-item-action>
                <v-icon>mdi-account</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-action>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
        <v-list class="grow">
          <v-list-item v-for="link in flattenArr" :key="link.destination" link :to="link.destination">
            <v-list-item-title v-text="link.label"></v-list-item-title>
          </v-list-item>
          <v-list-item :href="subscriptionManage" target="_blank">
            <v-list-item-title>Gestioneaza abonament</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-row>
    </v-navigation-drawer>
    <span>
      <v-app-bar app class="lightRed" id="notification" height="32px" v-if="userFree || userDisabled">
        <!-- <v-toolbar-content style="height: 32px !important"> -->
        <div class="text-black  warningText" v-if="userFree">Mai ai {{ daysBeforeExpire }} zile pana expira planul
          Gratuit. Contul tau se va prelungi automat.</div>
        <!-- Activeaza planul Advanced de <a :href="upgradeLink" target="_blank">aici -->
        <div class="text-black  warningText" v-else>Planul Gratuit a expirat. Activeaza planul Advanced de <a
            :href="upgradeLink" target="_blank">aici</a></div>
        <!-- </v-toolbar-content> -->
      </v-app-bar>
      <v-app-bar app :class="`primary text-black` + ((userFree || userDisabled) ? ` barWithNotification` : ``)"
        elevate-on-scroll>
        <template>
          <span class="hidden-sm-and-up">
            <v-btn @click="sidebar = !sidebar" icon color="black">
              <v-icon>mdi-hamburger</v-icon>
            </v-btn>
          </span>
          <router-link to="/" tag="span" style="cursor: pointer" class="text-black" color="black">
            <img class="w-auto hidden-xs-only" src="../../assets/rd-Artboard 2xxxhdpi.png" alt="Registrul Deseurilor"
              style="
    max-height: 60px;
" />
          </router-link>
          <v-app-bar-title class="text-black extraFlex">
            <v-tooltip top v-if="userImp">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="exitImpersonation()" color="secondary" v-bind="attrs" class="text-black min-w100"
                  v-on="on">
                  <v-icon>mdi-content-save</v-icon>
                  {{ userImp.name }}
                </v-btn>
              </template>
              <span>Iesi din impersonare</span>
            </v-tooltip>

          </v-app-bar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-xs-only">
            <span v-for="item in links" :key="item.label">
              <v-btn :to="!item.children ? item.destination : ''" text v-if="!item.children && !item.url"
                class="text-black fullHeight" color="black">
                <v-icon left dark>{{ item.icon }}</v-icon>
                {{ item.label }}
              </v-btn>
              <v-btn :href="item.url" text v-if="item.url" class="text-black fullHeight" color="black" target="_blank">
                <v-icon left dark>{{ item.icon }}</v-icon>
                {{ item.label }}
              </v-btn>
              <v-menu text offset-y v-if="item.children && item.children.length">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="text-black fullHeight" color="black">
                    {{ item.label }}
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item v-for="(it, index) in item.children" :key="index">
                    <v-btn block :to="it.destination" text>{{ it.label }}</v-btn>
                  </v-list-item>
                </v-list>
              </v-menu>
            </span>
            <profile-menu v-if="!$store.state.auth.loadingUser && $store.state.auth.isAuthenticated
            " :logo="$store.state.auth.user.avatar"></profile-menu>
          </v-toolbar-items>
        </template>
      </v-app-bar>
    </span>
  </nav>
</template>
<script>
import BurgerButton from '../Buttons/BurgerButton.vue';
import MobileNavbarItem from './Navbar/MobileNavbarItem.vue';
import NavbarItem from './Navbar/NavbarItem.vue';
import ProfileMenu from './Navbar/ProfileMenu/ProfileMenu.vue';
export default {
  // ======================
  // DATA
  // ======================
  data() {
    return {
      showMobileMenu: false,
      showProfileMenu: false,
      sidebar: false,
      helpLink: 'https://registrul-deseurilor.tawk.help/',
      subscriptionManage: 'https://billing.stripe.com/p/login/3cs2atfac1DxgRq3cc',
    };
  },
  // ======================
  // Components
  // ======================
  components: {
    NavbarItem,
    MobileNavbarItem,
    BurgerButton,
    // ProfileMenu,
    ProfileMenu,
  },
  watch: {

  },
  computed: {
    company() {
      return this.$store.state.auth.user.company
    },
    user() {
      return this.$store.state.auth.user
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    displayName() {
      return this.user ? this.user.name : ''
    },

    lastPayment() {
      if (this.userImp && this.userImp.email) {
        return this.userImp.payment_statuses.filter(p => p.endDate)[0]
      }
      return this.user.payment_statuses.filter(p => p.endDate)[0]
    },
    expireDate() {

      if (!this.lastPayment) {
        return new Date()
      }
      return new Date(this.lastPayment.endDate)
    },
    daysBeforeExpire() {
      let now = new Date()
      var Difference_In_Time = this.expireDate.getTime() - now.getTime()
      // To calculate the no. of days between two dates
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      return Math.floor(Difference_In_Days)
    },

    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    username() {
      return this.$store.state.auth.user.name
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    canSeeUsers() {
      return this.$store.state.auth.canSeeUsers
    },
    userPhone() {
      return this.userImp && this.userImp.phoneNumber ? this.userImp.phoneNumber : this.user.phoneNumber
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    },
    userEmail() {
      return this.userImp && this.userImp.email ? this.userImp.email : this.user.email
    },
    userCUI() {
      return this.vatId
    },
    vatId() {
      return this.$store.state.companies.vatId
    },
    upgradeLink() {
      return `https://registrul-deseurilor.ro/asp-payment-box/?product_id=7094&email=${this.userEmail}&cui=${this.userCUI}&nume=${this.userName}&phone=${this.userPhone}`
    },
    flattenArr() {
      const result = []
      this.links.map((obj) => {
        if (obj.children) {
          const el = {
            ...obj,
            ...{}
          }
          delete el.children
          result.push(el)
          Object.values(obj.children).map((v, i) => {
            result.push(v)
          })
        } else {
          result.push(obj)
        }
      })
      return result
    },
    links() {
      let organizationChildren = [{
        label: 'Companii',
        destination: '/companies',
        active: this.$route.name === 'DashboardCompanies',
      },
      {
        label: 'Puncte de lucru',
        destination: '/workingPoints',
        active: this.$route.name === 'WorkingPoints',
      },

      ]
      let workingPointLinks = [{
        label: 'Panou de bord',
        destination: '/',
        active: this.$route.name === 'Panou de bord',
      },
      {
        label: 'Evidenta deseuri',
        destination: '/wasteRecords',
        active: this.$route.name === 'DashboardWasteRecords',
      },
      {
        label: 'Parteneri',
        destination: '/partners',
        active: this.$route.name === 'Parteneri',
      },
      {
        label: 'Setari deseuri',
        destination: '/wasteCodesPerWorkingPoint',
        active: this.$route.name === 'DashboardWasteCodesPerWorkingPoint',
      },
      {
        label: 'Organizatie',
        destination: '/organization',
        active: this.$route.name === 'DashboardOrganization',
        children: organizationChildren
      },
      {
        label: 'Rapoarte',
        destination: '/reports',
        active: this.$route.name === 'Reports',
      },
      {
        label: 'Utilizatori',
        destination: '/users',
        active: this.$route.name === 'DashboardUsers',
      },
      ]

      let backToAdmin = {
        label: 'UTILIZATORI',
        destination: '/',
        active: this.$route.name === 'dashboardAdmin',
      }


      let adminLinks = [
        backToAdmin,
        {
        label: 'CRM',
        destination: '/crm',
        active: this.$route.name === 'DashboardCRM',
      },
        {
          label: 'Coduri de deseu',
          destination: '/wasteCodes',
          active: this.$route.name === 'wasteCodes',
        },

        {
          label: 'Statusuri',
          destination: '/status',
          active: this.$route.name === 'DashboardStatus',
          children: [
            {
              label: 'Statusuri user',
              destination: '/userStatus',
              active: this.$route.name === 'userStatus',
            },
            {
              label: 'Statusuri CRM',
              destination: '/crmStatus',
              active: this.$route.name === 'crmStatus',
            },
            {
              label: 'Subiecte CRM',
              destination: '/crmSubject',
              active: this.$route.name === 'crmSubject',
            },
          ]
        },
        {
          label: 'Liste',
          destination: '/lists',
          active: this.$route.name === 'lists',
        }
      ]

      if (this.isAdmin) {
        return adminLinks
      }
      if (!this.canSeeUsers) {
        workingPointLinks = workingPointLinks.filter(link => link.label != 'Utilizatori')
      }
      return workingPointLinks
    },
  },
  // ======================
  // Methods
  // ======================
  methods: {
    exitImpersonation() {
      return new Promise((resolve, reject) => {
        try {
          localStorage.removeItem('userImpId');
          this.$store.dispatch('fetchUserById', null);
          this.$router.push('/')
        } catch (error) {
          console.log("error", error)
          reject(error);
        }
      });
    },
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    toggleProfileMenu() {
      this.showProfileMenu = !this.showProfileMenu;
    },
    async logout() {
      window.localStorage.removeItem('access_token');
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
  created() {
    this.$store.dispatch('fetchUserVatId');
  }
};
</script>
<style scoped>
.min-w200 {
  min-width: 200px;
  display: block
}

.fullHeight {
  height: 100% !important
}

.barWithNotification {
  margin-top: 32px !important
}

.v-application .text-caption {
  /* font-size: .70rem!important; */
  line-height: .75rem;
  font-family: 'Rubik' !important;
}

.warningText {
  font-size: 19px !important
}

@media only screen and (max-width: 600px) {
  .warningText {
    font-size: 12px !important
  }
}

/* #notification v-toolbar__content {
  height: 32px;
}
header#notification.div {
  height: 32px;
} */
</style>
