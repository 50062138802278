<template>
  <div style="margin: 10px">
    <LayoutHeader title="Panou de bord" />
    <v-row no-gutters>
      <v-col>
        <DashboardCard title="Transporturi" :info="statistics.allWasteRecords" class="lightestGreen" />
      </v-col>
      <v-col>
        <DashboardCard title="Transporturi nefinalizate" :info="statistics.notFinalized" class="lightestRed" />
      </v-col>
      <v-col>
        <DashboardCard title="Coduri de deseu active" :info="statistics.activeWCs" class="lightestTurquoise" />
      </v-col>
      <v-col>
        <DashboardCard title="Puncte de lucru active" :info="statistics.activeWPs" class="lightestBlue" />
      </v-col>
      <v-col>
        <DashboardCard title="Parteneri activi" :info="statistics.activePartners" class="lightestYellow" />
      </v-col>
    </v-row>
    <div class="dashboardTileLarge">
      <v-select v-model="currentWorkingPoint" :items="workingPointsItems" item-text="displayName"
        label="Alege punctul de lucru" item-value="_id" return-object @input="changeContext()" />
      <v-layout v-if="currentWorkingPoint && !userDisabled && canPerformBasicCRUD && canAddRecord(currentWorkingPoint)">
        <div class="grid" v-if="activeWasteCodesPerWorkingPoint.length < 8">
          <sub-header class="mb-5 mt-5">Adauga evidenta deseuri</sub-header>
          <v-row class="buttonRow alignedRow">
            <v-tooltip top v-for="(wcPWP, idx) in activeWasteCodesPerWorkingPoint" :key="idx">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openWasteRecordDialog(wcPWP)" class="text-gray-500 primary marginedButton" v-bind="attrs"
                  v-on="on">
                  <v-icon left dark>mdi-plus</v-icon>
                  <span>{{ wcPWP.alias || wcPWP.wasteCode.code }}</span>
                </v-btn>
              </template>
              <span>{{ wcPWP.wasteCode.name }}</span>
            </v-tooltip>
          </v-row>
        </div>
        <v-select v-else v-model="wcToAdd" label="Adauga evidenta pentru" :items="activeWasteCodesPerWorkingPoint"
          item-text="displayName" return-object @input="openWasteRecordDialog(wcToAdd)" />
      </v-layout>
      <sub-header class="mb-5 mt-5">Centralizare cantitati deseuri</sub-header>
      <v-row class="buttonRow alignedRow">
        <v-menu
          v-model="dateRangeFilter.show"
          :close-on-content-click="false"
          :return-value.sync="pickerDates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dateRangeFilter.show = true" 
              class="text-gray-500 lightestGreen marginedButton">
              <span v-if="!dateRangeFilter.text" style="min-width: 230px;">
                Selecteaza perioada
              </span>
              <span v-else style="min-width: 230px;">{{ dateRangeFilter.text }}</span>
            </v-btn>
          </template>
          <v-date-picker v-model="pickerDates" no-title scrollable range>
            <v-spacer></v-spacer>
            <v-btn text color="error" @click="dateRangeFilter.show = false">
              Cancel
            </v-btn>
            <v-btn text color="secondary" @click="savePickedDates()">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
        <v-btn class="text-gray-500 lightestGreen marginedButton" v-for="filter in dateFilters" :key="filter.text"
          @click="filter.action(filter.value)" :disabled="currentDateFilter == filter.value">
          {{ filter.text }}
        </v-btn>
      </v-row>
      <v-data-table :items="wasteRecordStatistics" :headers="wasteRecordHeaders" hide-default-footer>
      </v-data-table>
      <sub-header class="mt-5">Cantitati deseuri nefinalizate</sub-header>
      <v-data-table :items="wasteRecordsNotFinalized" :headers="wasteRecordNotFinalizedHeaders" hide-default-footer
        class="notFinalizedTable" v-if="wasteRecordsNotFinalized.length">
        <template v-slot:[`item.registerDate`]="props">
          {{ parseDate(props.item.registerDate) }}
        </template>
        <template v-slot:[`item.actions`]="props" v-if="canPerformBasicCRUD && currentWorkingPoint && canAddRecord(currentWorkingPoint)">
          <p class="tableData">
          <div class="flex">
            <v-btn icon @click="finalizeWasteRecord(props.item)" color="primary" :disabled="userDisabled"
              class="finalizeButton">
              <v-icon>mdi-check-bold</v-icon>
              Finalizeaza
            </v-btn>
          </div>
          </p>
        </template>
      </v-data-table>
      <div v-else class="successMessage">
        <v-icon color="primary"> mdi-check-decagram</v-icon>
        Bravo, nu ai transporturi nefinalizate!
      </div>
    </div>
    <v-row class="mt-3 ml-5" v-if="isManager">
      <div class="dashboardTile" v-if="!userDisabled">
        <v-card-title class="lightestGreen">
          <sub-header>
            Ce vrei sa faci azi?
          </sub-header>
        </v-card-title>
        <v-card-text class="greenBorder">
          <v-row class="cardAction" v-if="userAdvanced && canPerformOrganizationChanges">
            <v-btn @click="buyWorkingPoint()" v-if="!canAddWorkingPoint && userAdvanced" color="secondary">
              Achizitioneaza punct de lucru
            </v-btn>
            <v-btn @click="openWorkingPointDialog()" v-if="canAddWorkingPoint" color="secondary">
              Adauga punct de lucru
            </v-btn>
          </v-row>
          <v-list v-for="workingPoint in managerWorkingPoints" :key="workingPoint._id">
            <div class="workingPointName">
              {{ workingPoint.displayName }}
            </div>
            <v-list-item v-for="action in actionsByWorkingPoint(workingPoint)" :key="action.text"
              @click="action.actionFunc(action)">
              <v-icon class="icons" color="secondary"
                v-if="action.action == ActionsToPerform.FILL_WCPWP">mdi-delete-empty</v-icon>
              <v-icon class="icons" color="secondary"
                v-if="action.action == ActionsToPerform.ADD_PARTNER">mdi-account-supervisor</v-icon>
              <v-icon class="icons" color="secondary"
                v-if="action.action == ActionsToPerform.ADD_WCPWP">mdi-plus-circle</v-icon>
              <v-icon class="icons" color="secondary"
                v-if="action.action == ActionsToPerform.ADD_RECORD">mdi-plus-circle</v-icon>
              {{ action.text }}
            </v-list-item>
          </v-list>
          <div v-if="remainingWorkingPoints" class="workingPointName italic">
            Mai poti adauga <strong>{{ remainingWorkingPoints }}</strong> {{ remainingWorkingPoints > 1 ? ` puncte de
            lucru` :
          ' punct de lucru' }}
          </div>
        </v-card-text>
      </div>
      <div class="dashboardTile" v-if="!userDisabled">
        <v-card-title class="lightestBlue">
          <sub-header>
            Ce ai de facut
          </sub-header>
        </v-card-title>
        <v-card-text class="blueBorder">
          <div v-if="hasNoThingsToDo && hasWorkingPoint" class="successMessage">
            <v-icon color="primary"> mdi-check-decagram</v-icon>
            Bravo, ai facut tot ce trebuia!
          </div>
          <div v-else>
            <v-btn @click="openWorkingPointDialog()" v-if="canAddWorkingPoint && !hasWorkingPoint" color="primary">
              Adauga punct de lucru
            </v-btn>
            <v-list v-for="workingPoint in managerWorkingPoints" :key="workingPoint._id">
              <div v-if="thingsToDoByWorkingPoint(workingPoint._id).length">
                <div class="workingPointName">
                  {{ workingPoint.displayName }}
                </div>
                <v-list-item v-for="action in thingsToDoByWorkingPoint(workingPoint._id.toString())" :key="action.text"
                  @click="action.actionFunc(action)">
                  <v-icon class="icons" color="primary"
                    v-if="action.action == ActionsToPerform.FILL_WCPWP">mdi-delete-empty</v-icon>
                  <v-icon class="icons" color="primary"
                    v-if="action.action == ActionsToPerform.ADD_PARTNER">mdi-account-supervisor</v-icon>
                  {{ action.text }}
                </v-list-item>
              </div>
            </v-list>
          </div>
        </v-card-text>
      </div>
    </v-row>
    <WasteCodesPerWorkingPointDialog v-if="wasteCodesDialog" :closeDialog="closeWasteCodesPerWorkingPoint"
      :workingPoint="partnerWorkingPoint" />
    <CreateWorkingPoint v-if="createDialog" :closeDialog="closeCreateDialog" :companies="companies"
      :createCb="refreshData" />
    <CreatePartner v-if="partnerDialog" :closeDialog="closePartnerDialog" :createCb="refreshData"
      :workingPoint="partnerWorkingPoint"/>
    <!-- <CreateCar v-if="carDialog" :closeDialog="closeCarDialog" :partner="currentPartner"
      :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :createCb="refreshData" />
    <CreateDriver v-if="driverDialog" :closeDialog="closeDriverDialog" :partner="currentPartner"
      :createCb="refreshData" /> -->
    <StartGuide
      v-if="(userStartGuideStep >= 0 || startGuideWasteCodes || startGuidePartner || startGuideOutro) && startGuideDialog"
      :step="userStartGuideStep" :closeDialog="closeStartGuide" :refreshData="refreshData" />
    <CreateWasteRecord v-if="wasteRecordDialog" :closeDialog="closeWasteRecordDialog"
      :carTypes="listsByCategory(ListCategories.MIJLOC_TRANSPORT)" :wasteCodePWP="currentWasteCodePWP"
      :createCb="refreshData" :workingPoint="currentWorkingPoint"
      :partners="partnersByWorkingPoint(currentWorkingPoint)" />
  </div>
</template>
<script>
import moment from "moment-timezone";
import { ActionsToPerform } from '../../src/common/ActionsToPerform';
import { PaymentPlans } from '../../src/common/PaymentPlans';
import {
  ListCategories
} from '../common/ListCategories';
import AddButton from '../components/Buttons/AddButton.vue';
// import CreateCar from '../components/Modals/Create/CreateCar.vue';
// import CreateDriver from '../components/Modals/Create/CreateDriver.vue';
import CreatePartner from '../components/Modals/Create/CreatePartner.vue';
import CreateWasteRecord from '../components/Modals/Create/CreateWasteRecord.vue';
import CreateWorkingPoint from '../components/Modals/Create/CreateWorkingPoint.vue';
import StartGuide from '../components/Modals/StartGuideModals/StartGuide.vue';
import WasteCodesPerWorkingPointDialog from '../components/Modals/WasteCodesPerWorkingPointDialog.vue';
import SectionHeadline from '../components/Typography/SectionHeadline.vue';
import SubHeader from '../components/Typography/SubHeader.vue';
import DashboardCard from '../components/Widgets/DashboardCard.vue';
import LayoutHeader from '../components/Widgets/LayoutHeader.vue';
export default {
  components: {
    LayoutHeader,
    SectionHeadline,
    AddButton,
    DashboardCard,
    SubHeader,
    // CreateCar,
    CreatePartner,
    // CreateDriver,
    WasteCodesPerWorkingPointDialog,
    CreateWorkingPoint,
    StartGuide,
    CreateWasteRecord
  },
  data() {
    return {
      startGuideDialog: true,
      currentDateFilter: 'ALL',
      dateFilters: [{
        text: 'Luna curenta',
        value: 'THIS_MONTH',
        action: (value) => {
          this.currentDateFilter = value
          this.getWasteRecordStatistics({type: "THIS_MONTH"})
        }
      },
      {
        text: 'Luna trecuta',
        value: 'LAST_MONTH',
        action: (value) => {
          this.currentDateFilter = value
          this.getWasteRecordStatistics({type: "LAST_MONTH"})
        }
      },
      {
        text: 'Anul curent',
        value: 'THIS_YEAR',
        action: (value) => {
          this.currentDateFilter = value
          this.getWasteRecordStatistics({type: "THIS_YEAR"})
        }
      },
      {
        text: 'Anul trecut',
        value: 'LAST_YEAR',
        action: (value) => {
          this.currentDateFilter = value
          this.getWasteRecordStatistics({type: "LAST_YEAR"})
        }
      },
      {
        text: 'Toate',
        value: 'ALL',
        action: (value) => {
          this.currentDateFilter = value
          this.getWasteRecordStatistics({})
        }
      }
      ],
      wasteRecordHeaders: [
        {
          text: 'Cod deseu',
          value: 'wasteCode',
          width: '25%'
        },
        {
          text: 'Generat (to)',
          value: 'generated',
          width: '25%'
        },
        {
          text: 'Valorificat (to)',
          value: 'valued',
          width: '25%'
        },
        {
          text: 'Eliminat (to)',
          value: 'eliminated',
          width: '25%'
        }
      ],
      wasteRecordNotFinalizedHeaders: [{
        text: 'Cod deseu',
        value: 'wasteCode.code',
        width: '25%'
      },
      {
        text: 'Data',
        value: 'registerDate',
        width: '25%'
      },
      {
        text: 'Cantitate',
        value: 'quantity',
        width: '25%'
      },
      {
        text: 'Actiuni',
        value: 'actions',
        width: '25%'
      }
      ],
      partnerDialog: false,
      // carDialog: false,
      // driverDialog: false,
      // currentPartner: null,
      currentWorkingPoint: null,
      partnerWorkingPoint: null,
      wasteCodesDialog: false,
      createDialog: false,
      wasteRecordDialog: false,
      currentWasteCodePWP: null,
      pickerDates: [],
      dateRangeFilter: {
        show: false, startDate: null, endDate: null, text: null
      },
    };
  },
  watch: {
    firstWorkingPoint() {
      if (this.firstWorkingPoint) {
        this.currentWorkingPoint = this.firstWorkingPoint
        this.changeContext()
      }
    }
  },
  computed: {
    canPerformBasicCRUD() {
      return this.$store.state.auth.canPerformBasicCRUD
    },
    canPerformOrganizationChanges() {
      return this.$store.state.auth.canPerformOrganizationChanges
    },
    isManager() {
      return this.$store.state.auth.isManager
    },
    partners() {
      return this.$store.state.partners.partners
    },
    ListCategories() {
      return ListCategories
    },
    user() {
      return this.$store.state.auth.user
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
    userImp() {
      return this.$store.state.auth.userImp
    },
    expiredMessage() {
      let lastPlan
      if (this.userImp) {
        lastPlan = this.userImp.payment_statuses ? this.userImp.payment_statuses[0]?.productId : PaymentPlans.FREE
      } else {
        lastPlan = this.user.payment_statuses ? this.user.payment_statuses[0]?.productId : PaymentPlans.FREE
      }
      let planName = lastPlan == PaymentPlans.ADVANCED ? "Advanced" : "Free"
      return `Planul tau ${planName} a expirat.`
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userLoading() {
      return this.$store.state.auth.userLoading
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    userStartGuideStep() {
      // sau daca are mai multe wps, desi ar trebui sa fie echivalente
      if(this.userLoading) {
        return -1
      }
      if (this.userDisabled || this.workingPoints.length > 1) {
        return -1
      }
      if (this.loadingThingsToDo || this.loadingWorkingPoints) {
        return -1
      }
     
      if (!this.hasWorkingPoint) {
        return 1
      }
      let thingsToDo = this.thingsToDoByWorkingPoint(this.workingPoints[0]._id)
      if (thingsToDo.find(t => t.action == this.ActionsToPerform.ADD_PARTNER)) {
        return 2
      }
      // if(thingsToDo.find(t => t.action == this.ActionsToPerform.FILL_WCPWP)) {
      //   return 3
      // }
      return -1
    },
    hasWorkingPoint() {
      if(this.loadingWorkingPoints) {
        return true
      }
      return this.workingPoints.length > 0
    },
    allowedWorkingPoints() {
      if (this.userImp) {
        return this.userImp.allowedWorkingPoints
      }
      return this.user ? this.user.allowedWorkingPoints : 0
    },
    remainingWorkingPoints() {
      return this.allowedWorkingPoints - this.workingPoints.length
    },

    canAddWorkingPoint() {
      // if(this.user.permissions && this.user.permissions.length) {
      //   return false
      // }
      if (this.userAdvanced) {
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      if (this.userFree) {
        // return this.workingPoints.length < 1
        //TRIAL HAS ALL FEATURES ENABLED
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      return false
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
    ActionsToPerform() {
      return ActionsToPerform
    },
    PaymentPlans() {
      return PaymentPlans
    },
    statistics() {
      return this.$store.state.dashboard.statistics
    },
    thingsToDo() {
      return this.$store.state.dashboard.thingsToDo
    },
    loadingThingsToDo() {
      return this.$store.state.dashboard.loading
    },
    loadingWorkingPoints() {
      return this.$store.state.workingPoints.loading
    },
    startGuideWasteCodes() {
      return this.$store.state.dashboard.startGuideWasteCodes
    },
    startGuidePartner() {
      return this.$store.state.dashboard.startGuidePartner
    },
    startGuideOutro() {
      return this.$store.state.dashboard.startGuideOutro
    },
    hasNoThingsToDo() {
      let hasNoThings = true
      Object.keys(this.thingsToDo).map(k => {
        if (this.thingsToDo[k].length) {
          hasNoThings = false
        }
      })
      return hasNoThings
    },
    wasteRecords() {
      return this.$store.state.wasteRecords.wasteRecords
    },
    wasteRecordStatistics() {
      return this.$store.state.wasteRecords.wasteRecordStatistics
    },
    wasteRecordsNotFinalized() {
      return this.wasteRecords.filter(wr => wr.status == 'NEFINALIZAT')
    },
    workingPointsItems() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      }).filter(wp => wp.isActive)
    },
    workingPoints() {
      return this.$store.state.workingPoints.workingPoints
    },
    managerWorkingPoints() {
      if (this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if (this.user.permissions) {
        let managerPerm = this.user.permissions.find(p => p.role == 'MANAGER')
        if (managerPerm) {
          return this.workingPoints.filter(wp => managerPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
    recorderWorkingPoints() {
      if (this.canPerformOrganizationChanges) {
        // nu e gueest
        return this.workingPoints
      }
      if (this.user.permissions) {
        let recorderPerm = this.user.permissions.find(p => p.role == 'DATA_RECORDER')
        if (recorderPerm) {
          return this.workingPoints.filter(wp => recorderPerm.workingPoints.includes(wp._id))
        }
      }
      return []
    },
    firstWorkingPoint() {
      let wp = this.$store.state.workingPoints.firstWorkingPoint
      if (wp) {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
      }
      return wp
    },
    companies() {
      return this.$store.state.companies.companies
    },
    userPhone() {
      return this.userImp && this.userImp.phoneNumber ? this.userImp.phoneNumber : this.user.phoneNumber
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    },
    userEmail() {
      return this.userImp && this.userImp.email ? this.userImp.email : this.user.email
    },
    userCUI() {
      return this.companies[this.companies.length - 1].vatId
    },
    wasteCodesPerWorkingPoint() {
      return this.$store.state.wasteCodesPerWorkingPoint.wasteCodesPerWorkingPoint
    },
    activeWasteCodesPerWorkingPoint() {
      return this.wasteCodesPerWorkingPoint.filter(wc => wc.isActive).map(wc => {
        wc.displayName = `${wc.alias || wc.wasteCode.code} (${wc.wasteCode.name})`
        return wc
      })
    },
    lists() {
      return this.$store.state.wasteCodes.lists
    },
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    resetRangeInfo() {
      this.dateRangeFilter.startDate = null;
      this.dateRangeFilter.endDate = null;
      this.dateRangeFilter.text = null;
      this.pickerDates = [];
    },
    savePickedDates() {
      if(this.pickerDates.length != 2) {
        return
      }
      if(new Date(this.pickerDates[0]) > new Date(this.pickerDates[1])) {
        this.pickerDates.reverse();
      }

      this.dateRangeFilter.startDate = this.pickerDates[0];
      this.dateRangeFilter.endDate = this.pickerDates[1];
      let filterText = `${this.formatDate(this.pickerDates[0])} - ${this.formatDate(this.pickerDates[1])}`;
      this.dateRangeFilter.text = filterText;

      this.currentDateFilter = 'CUSTOM';
      this.getWasteRecordStatistics({type: "CUSTOM", dateRange: [this.dateRangeFilter.startDate, this.dateRangeFilter.endDate]})
      this.dateRangeFilter.show = false;
    },
    canAddRecord(workingPoint) {     
      return this.managerWorkingPoints.map(wp => wp._id).includes(workingPoint._id) ||
        this.recorderWorkingPoints.map(wp => wp._id).includes(workingPoint._id)
    },
    closeStartGuide() {
      this.startGuideDialog = false
    },
    buyWorkingPoint() {
      let url = `https://registrul-deseurilor.ro/asp-payment-box/?product_id=7094&email=${this.userEmail}&cui=${this.userCUI}&nume=${this.userName}&phone=${this.userPhone}`
      window.open(url, "_blank");
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    actionsByWorkingPoint(workingPoint) {
      return [
        {
          text: "Adauga cod de deseu",
          workingPoint: workingPoint,
          action: this.ActionsToPerform.ADD_WCPWP,
          actionFunc: (thing) => {
            this.openWasteCodesPerWorkingPoint(thing.workingPoint)
          }
        },
        {
          text: "Completeaza date deseuri",
          workingPoint: workingPoint,
          action: this.ActionsToPerform.FILL_WCPWP,
          actionFunc: (thing) => {
            this.openWasteCodesPerWorkingPoint(thing.workingPoint)
          }
        },
        {
          text: "Adauga partener nou",
          workingPoint: workingPoint,
          action: this.ActionsToPerform.ADD_PARTNER,
          actionFunc: (thing) => {
            this.openPartnerDialog(thing.workingPoint)
          }
        },
      ]
    },
    exportExcel(workingPoint) {
      this.$store.dispatch("exportExcel", {
        workingPointId: workingPoint._id,
        cb: (response) => {
          let fileName = "Raport.xlsx"
          let fileUrl = window.URL.createObjectURL(response.data);
          let fileLink = document.createElement('a');
          fileLink.href = fileUrl;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink)
          fileLink.click();
        }
      })
    },
    thingsToDoByWorkingPoint(workingPointId) {
      let things = this.thingsToDo[workingPointId]
      if (!things) {
        return []
      }

      return things.map(thing => {
        thing.text = this.getThingToDoDisplay(thing)
        thing.actionFunc = this.getThingToDoAction(thing)
        return thing
      })
    },
    getThingToDoDisplay(thing) {
      if (thing.action == this.ActionsToPerform.FILL_WCPWP) {
        return 'Completeaza date coduri deseu'
      }
      if (thing.action == this.ActionsToPerform.ADD_PARTNER) {
        return 'Adauga partener'
      }
      // if (thing.action == this.ActionsToPerform.ADD_CAR) {
      //   return 'Adauga masina pentru ' + thing.partner.name
      // }
      // if (thing.action == this.ActionsToPerform.ADD_DRIVER) {
      //   return 'Adauga sofer pentru ' + thing.partner.name
      // }
      return 'other'
    },
    getThingToDoAction(thing) {
      if (thing.action == this.ActionsToPerform.FILL_WCPWP) {
        return (thing) => {
          this.openWasteCodesPerWorkingPoint(thing.workingPoint)
        }
      }
      if (thing.action == this.ActionsToPerform.ADD_PARTNER) {
        return (thing) => {
          this.openPartnerDialog(thing.workingPoint)
        }
      }
      // if (thing.action == this.ActionsToPerform.ADD_CAR) {
      //   return (th) => {
      //     this.openCarDialog(th.partner)
      //   }
      // }
      // if (thing.action == this.ActionsToPerform.ADD_DRIVER) {
      //   return (thing) => {
      //     this.openDriverDialog(thing.partner)
      //   }
      // }
      return () => {
      }
    },
    openWasteCodesPerWorkingPoint(workingPoint) {
      this.wasteCodesDialog = true
      this.partnerWorkingPoint = workingPoint
    },
    closeWasteCodesPerWorkingPoint() {
      this.wasteCodesDialog = false
      this.partnerWorkingPoint = null
      this.refreshData()
    },
    goToWorkingPoints() {
      this.$router.push('/organization/addWorkingPoint')
    },
    finalizeWasteRecord(wasteRecord) {
      this.$store.dispatch('finalizeWasteRecord', {
        model: wasteRecord,
        cb: () => {
          this.$store.dispatch("fetchWasteRecordsPerWorkingPoint", {
            workingPointId: this.currentWorkingPoint._id
          })
          this.$store.dispatch('fetchUserStatistics')
        }
      });
    },
    changeContext() {
      if (!this.currentWorkingPoint) {
        return
      }
      this.$store.dispatch("fetchWasteCodesPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
      this.$store.dispatch("fetchWasteRecordsPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
      this.$store.dispatch("fetchWasteRecordsStatisticsPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
      })
    },
    getWasteRecordStatistics(interval) {
      if(this.currentDateFilter !== 'CUSTOM') {
        this.resetRangeInfo();
      }
      this.$store.dispatch("fetchWasteRecordsStatisticsPerWorkingPoint", {
        workingPointId: this.currentWorkingPoint._id,
        interval: interval
      })
    },
    openPartnerDialog(workingPoint) {
      this.partnerDialog = true
      this.partnerWorkingPoint = workingPoint
    },
    closePartnerDialog() {
      this.partnerDialog = false
      this.partnerWorkingPoint = null
    },
    openPartnerDialogForUpdate(partner) {
      this.partnerWorkingPoint = workingPoint
      this.partnerDialog = true
    },
    listsByCategory(category) {
      return this.lists[category]
    },
   
    openWorkingPointDialog() {
      this.createDialog = true
    },
    closeCreateDialog() {
      this.createDialog = false
    },
    refreshData() {
      this.$store.dispatch('fetchUserStatistics')
      this.$store.dispatch('fetchThingsToDo')
      this.changeContext()
    },
    openWasteRecordDialog(wasteCodePerWorkingPoint) {
      this.wasteRecordDialog = true
      this.wcToAdd = null
      this.currentWasteCodePWP = wasteCodePerWorkingPoint
    },
    closeWasteRecordDialog() {
      this.wasteRecordDialog = false
      this.updating = false
      this.currentWasteCodePWP = null
    },
    listsByCategory(category) {
      return this.lists[category]
    },
    partnersByWorkingPoint(workingPoint) {
      if (!workingPoint) {
        return []
      }
      let partners = this.partners.filter(p => p.isActive)
      // let partners = this.partners.filter(p => p.workingPoint._id == workingPoint._id)
      return partners
    },
    getPartners() {
      this.$store.dispatch('fetchPartners')
    }
  },
  created() {
    this.$store.dispatch('fetchWorkingPoints', {lean: true})
    this.refreshData()
    this.getPartners()
    this.$store.dispatch('fetchLists', {})
    // this.$store.dispatch('fetchCompanies');
  }
};
</script>
<style scoped>
.dashboardTile {
  width: 50%;
  padding: 10px
}

.workingPointName {
  font-size: 15px;
}

.cardAction {
  justify-content: end;
  padding-top: 20px;
}

@media only screen and (max-width: 600px) {
  .cardAction {
    justify-content: start;
    padding-top: 20px;
    padding-bottom: 10px
  }

  .dashboardTile {
    width: 100%
  }

  .finalizeButton {
    justify-content: end
  }
}

.dashboardTileLarge {
  margin-right: 20px;
  margin-left: 20px;
}

.alignedRow {
  margin-left: 1px
}

.icons {
  padding: 5px;
}

.successMessage {
  color: #0d9cb4;
  padding-top: 10px;
  padding-left: 10px
}

.greenBorder {
  border-left: 1px solid #cbefa7
}

.blueBorder {
  border-left: 1px solid #9ed7e1
}

.buttonHeader {
  margin-left: 20px;
  margin-right: 40px
}

.italic {
  font-style: italic;
}
</style>
