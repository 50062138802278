import toastr from "../../toastr";
import axiosInstance from "../axiosWrapper";
export default {
  //===========================
  // STATE
  //===========================
  state: {
    crm: [],
    totalCrm: 0,
    crmStatuses: [],
    crmSubjects: [],
    crmLogs: [],
    crmCompany: null,
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_CRM(state, data) {
      state.crm = data.items.map((crm) => {
        if (crm.email && crm.email.length) {
          crm.hasEmail = true;
        }
        if (crm.name && crm.name.length) {
          crm.hasName = true;
        }
        if (crm.phone && crm.phone.length) {
          crm.hasPhone = true;
        }
        if (crm.vatId && crm.vatId.length) {
          crm.hasVatId = true;
        }

        if (crm.followUp) {
          crm.hasFollowUp = true;
          crm.followUp = new Date(crm.followUp);
        }
        return crm;
      });
      state.totalCrm = data.total;
    },
    SET_CRM_COMPANY(state, data) {
      state.crmCompany = data;
    },

    SET_CRM_STATUSES(state, data) {
      state.crmStatuses = data;
    },

    SET_CRM_SUBJECTS(state, data) {
      state.crmSubjects = data;
    },

    SET_CRM_LOGS(state, data) {
      state.crmLogs = data;
    },
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async deleteCRMStatus({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.delete(
          `${process.env.VUE_APP_API_URL}/crmStatus/${model._id}`,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Statusul a fost sters!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async deleteCRMSubject({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.delete(
          `${process.env.VUE_APP_API_URL}/crmSubject/${model._id}`,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Subiectul a fost sters!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async updateCRMStatus({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/crmStatus/${model._id}`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Statusul a fost updatat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async updateCRMSubject({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/crmSubject/${model._id}`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Subiectul a fost updatat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async createCRMStatus({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crmStatus`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Statusul a fost adaugat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async createCRMSubject({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crmSubject`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Subiectul a fost adaugat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async fetchCRMStatuses({ commit }) {
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/crmStatus`;

        const res = await axiosInstance.get(url, {
          withCredentials: true,
        });
        if (res.status === 200) {
          commit("SET_CRM_STATUSES", res.data);

          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCRMSubjects({ commit }) {
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/crmSubject`;

        const res = await axiosInstance.get(url, {
          withCredentials: true,
        });
        if (res.status === 200) {
          commit("SET_CRM_SUBJECTS", res.data);

          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCRM({ commit }, options) {
      let page = options.page || 1;
      let perPage = options.perPage || 25;
      let search = options.search || "";
      let cb = options.cb;
      let filter =
        options.filter && options.filter != {}
          ? JSON.stringify(options.filter)
          : null;
      try {
        let url = `${process.env.VUE_APP_API_URL}/crm?page=${page}&perPage=${perPage}`;
        if (search && search.length) {
          url = `${url}&search=${search}`;
        }

        if (filter && filter.length) {
          url = `${url}&filter=${filter}`;
        }
        const res = await axiosInstance.get(url, {
          withCredentials: true,
        });
        if (res.status === 200) {
          commit("SET_CRM", res.data);
          if (cb) {
            cb();
          }
          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async exportCRM({ commit }, options) {
      let cb = options.cb;
      let search = options.search || "";
      let filter =
        options.filter && options.filter != {}
          ? JSON.stringify(options.filter)
          : null;

      let queries = [];
      if (search && search.length) {
        queries.push(`search=${search}`);
      }
      if (filter) {
        queries.push(`filter=${filter}`);
      }

      let queryString = "";
      if (queries.length) {
        queryString = `?${queries.join("&")}`;
      }
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/crm/export${queryString}`,
          {
            withCredentials: true,
            responseType: "blob",
          }
        );
        cb(res);
      } catch (error) {
        console.log(error);
        return this.error;
      }
    },

    async createCRM({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crm`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Clientul a fost adaugat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async deleteCRM({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.delete(
          `${process.env.VUE_APP_API_URL}/crm/${model._id}`,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Clientul a fost sters!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async updateCRM({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/crm/${model._id}`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 200) {
          toastr.success("Clientul a fost updatat!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async fetchCRMLogs({ commit }, crmId) {
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/crmLogs/${crmId}`;

        const res = await axiosInstance.get(url, {
          withCredentials: true,
        });
        if (res.status === 200) {
          commit("SET_CRM_LOGS", res.data);

          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchCompanyFromANAF({ commit }, data) {
      let vatId = data.vatId;
      let cb = data.cb;
      try {
        let url = `${process.env.VUE_APP_API_URL}/crm/companyName/${vatId}`;

        const res = await axiosInstance.get(url, {
          withCredentials: true,
        });
        if (res.status === 200) {
          commit("SET_CRM_COMPANY", res.data);
          cb(res.data);

          return true;
        }
      } catch (error) {
        console.log(error);
      }
    },

    /**
     * export const getCompanyNameFromANAF = async (vatId) => {
  const response =  await axiosInstance.get(`/crm/companyName/${vatId}`);
  return response.data
}
     */

    async createCRMLog({ commit }, formData) {
      let model = formData.model;
      let cb = formData.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crmLogs`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Observatia a fost adaugata!");
        }
      } catch (error) {
        console.log(error);
        return this.error;
      }
      cb();
    },

    async generateProforma({ commit }, data) {
      const id = data.crmId;
      const cb = data.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crm/proforma/${id}`,
          {},
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Proforma a fost generata!");
        }
      } catch (error) {
        console.log(error);
        toastr.error(error);
      } finally {
        cb();
      }
    },

    async sendProforma({ commit }, data) {
      const id = data.id;
      const model = data.model;
      const cb = data.cb;
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/crm/sendProforma/${id}`,
          model,
          {
            withCredentials: true,
          }
        );
        if (res.status === 201) {
          toastr.success("Proforma a fost trimisa!");
        }
      } catch (error) {
        console.log(error);
        toastr.error(error);
      } finally {
        cb();
      }
    },
  },
};
