<template>
	<v-row style="margin-bottom: 10px">
		<div style="margin-left: 20px">
			<section-headline >
				{{title}}
			</section-headline>
			<div class="italic" v-if="userDisabled">
				{{ expiredMessage }}
			</div>
		</div>
		<v-divider style="border-block: white; width: 50px"/>
		<section-headline class="buttonHeader">
			<v-btn v-if="userDisabled" class="secondary" @click="upgradePlan">
				Activeaza planul
			</v-btn>
			<v-btn  v-if="userFree && !dontDistrurbFree" class="secondary" @click="upgradePlan">
				Activeaza planul advanced
			</v-btn>
			</section-headline>
	</v-row>
</template>
<script>
import { PaymentPlans } from '../../../src/common/PaymentPlans';
import SectionHeadline from '../../components/Typography/SectionHeadline.vue';
import SubHeader from '../../components/Typography/SubHeader.vue';
export default {
  components: {
    SectionHeadline,
    SubHeader
  },
	props: [
		'dontDistrurbFree', 'title'
	],
  data() {
    return {
    };
  },
  watch: {
  },
  computed: {
    user() {
      return this.$store.state.auth.user
    },
    userImp() {
      return this.$store.state.auth.userImp
    },

    expiredMessage() {
      let lastPlan
      if (this.userImp) {
        lastPlan = this.userImp.payment_statuses ? this.userImp.payment_statuses[0]?.productId : PaymentPlans.FREE
      } else {
        lastPlan = this.user.payment_statuses ? this.user.payment_statuses[0]?.productId : PaymentPlans.FREE
      }
      let planName = lastPlan == PaymentPlans.ADVANCED ? "Advanced" : "Free"
      return `Planul tau ${planName} a expirat.`
    },
    userDisabled() {
      return this.$store.state.auth.userDisabled
    },
    userAdvanced() {
      return this.$store.state.auth.userAdvanced
    },
    userFree() {
      return this.$store.state.auth.userFree
    },
    hasWorkingPoint() {
      return this.workingPoints.length > 0
    },
    allowedWorkingPoints() {
      if (this.userImp) {
        return this.userImp.allowedWorkingPoints
      }
      return this.user ? this.user.allowedWorkingPoints : 0
    },
    remainingWorkingPoints() {
      return this.allowedWorkingPoints - this.workingPoints.length
    },
    canAddWorkingPoint() {
      if (this.userAdvanced) {
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      if (this.userFree) {
        //return this.workingPoints.length < 1
        //TRIAL HAS ALL FEATURES ENABLED
        return this.workingPoints.length < this.allowedWorkingPoints
      }
      return false
    },
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },

    workingPoints() {
      return this.$store.state.workingPoints.workingPoints.map(wp => {
        wp.displayName = `${wp.company.companyName} \\ ${wp.name}`
        return wp
      })
			// .filter(wp => wp.isActive)
    },
    vatId() {
      return this.$store.state.companies.vatId
    },
    userPhone() {
      return this.userImp && this.userImp.phoneNumber ? this.userImp.phoneNumber : this.user.phoneNumber
    },
    userName() {
      return this.userImp && this.userImp.name ? this.userImp.name : this.user.name
    },
    userEmail() {
      return this.userImp && this.userImp.email ? this.userImp.email : this.user.email
    },
    userCUI() {
      return this.vatId
    }
  },
  methods: {
    upgradePlan() {
      let url = `https://registrul-deseurilor.ro/asp-payment-box/?product_id=7094&email=${this.userEmail}&cui=${this.userCUI}&nume=${this.userName}&phone=${this.userPhone}`
      window.open(url, "_blank");
    }
  },
  created() {
    // this.$store.dispatch('fetchUserVatId');
  }
};
</script>
<style scoped>
.dashboardTile {
  width: 50%;
  padding: 10px
}
.workingPointName {
  font-size: 15px;
}
.cardAction {
  justify-content: end; 
  padding-top: 20px;
}
@media only screen and (max-width: 600px) {
  .cardAction {
    justify-content: start; 
    padding-top: 20px;
    padding-bottom: 10px
  }
  .dashboardTile {
   width: 100%
  }
  .finalizeButton {
    justify-content: end
  }
}
.dashboardTileLarge {
  margin-right: 20px;
  margin-left: 20px;
}
.alignedRow {
  margin-left: 1px
}
.icons {
  padding: 5px;
}
.successMessage {
  color: #0d9cb4;
  padding-top: 10px;
  padding-left: 10px
}
.greenBorder {
  border-left: 1px solid #cbefa7
}
.blueBorder {
  border-left: 1px solid #9ed7e1
}
.buttonHeader {
  margin-left: 20px; margin-right: 40px
}
.italic {
  font-style: italic;
}
</style>
