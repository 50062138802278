import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';

export default {
  //===========================
  // STATE
  //===========================
  state: {
    users: [],
    totalUsers: 0,
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_USERS(state, data) {
      state.users = data
    },
    SET_TOTAL_USERS(state, data) {
      state.totalUsers = data
    }
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async fetchUsersForUser({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
   
      let cb = options.cb
      let filter = options.filter && options.filter != {} ? JSON.stringify(options.filter) : null
      try {
        let url = `${process.env.VUE_APP_API_URL}/pendingUsers?page=${page}&perPage=${perPage}`
        if (search && search.length) {
          url = `${url}&search=${search}`
        }
      
        if (filter && filter.length) {
          url = `${url}&filter=${filter}`
        }
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_USERS', res.data.items);
          commit('SET_TOTAL_USERS', res.data.total);
          if(cb) {
            cb()
          }
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },

    async saveUsers({ commit }, options) {
      let users = options.models
     
      let cb = options.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/pendingUsers`
      
        const res = await axiosInstance.post(
          url,
          users,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Utilizatorii au fost creati!")
          if(cb) {
            cb()
          }
          return true;
        } else {
          // console.log(res)
          // toastr.error(res.data.message)
        }
      } catch (error) {
        toastr.error(error)
      
        // toastr.error(error)
      }
    },

    async editUser({ commit }, options) {
      let user = options.model
     
      let cb = options.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/pendingUsers/${user._id}`
      
        const res = await axiosInstance.put(
          url,
          user,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Utilizatorul a fost actualizat!")
          if(cb) {
            cb()
          }
          return true;
        } else {
          console.log(res)
          console.log(res.data)
          // toastr.error(res.data.message)
        }
      } catch (error) {
        console.log("errorrr", error)
      
        // toastr.error(error)
      }
    },

    async deleteUser({ commit }, options) {
      let id = options.id
     
      let cb = options.cb
      try {
        let url = `${process.env.VUE_APP_API_URL}/pendingUsers/${id}`
      
        const res = await axiosInstance.delete(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Utilizatorul a fost sters!")
          if(cb) {
            cb()
          }
          return true;
        } else {
          console.log(res)
          console.log(res.data)
          // toastr.error(res.data.message)
        }
      } catch (error) {
        console.log("errorrr", error)
      
        // toastr.error(error)
      }
    },
  },
};
