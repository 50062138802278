import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    userStatuses: [],
    userStatusesTotal: 0,
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_USER_STATUSES(state, data) {
      state.userStatuses = data
    },
    SET_USER_STATUSES_TOTAL(state, data) {
      state.userStatusesTotal = data
    },
  
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async deleteUserStatus({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.delete(
          `${process.env.VUE_APP_API_URL}/userStatus/${model._id}`,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
   
    async updateUserStatus({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/userStatus/${model._id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async createUserStatus({ commit }, formData) {
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.post(
          `${process.env.VUE_APP_API_URL}/userStatus`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          toastr.success("Campul a fost adaugat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchUserStatuses({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      let filter = options.filter
      // let c
      try {
        let url = `${process.env.VUE_APP_API_URL}/userStatus/?page=${page}&perPage=${perPage}`
        if (search && search.length) {
          url = `${url}&search=${search}`
        }
        if(filter) {
          url = `${url}&filter=${JSON.stringify(filter)}`
        }
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_USER_STATUSES', res.data);
          commit('SET_USER_STATUSES_TOTAL', res.data.length);
         
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
