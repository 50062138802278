export const getUserMaxRole = (userInfo) => {
  let role = userInfo.role

  // ia cel mai mare rol din permisiuni, daca are...
  if(userInfo.permissions && role == 'GUEST') {
    let allRoles = userInfo.permissions.map(p => p.role)
    if(allRoles.includes('MANAGER')) {
      role = 'MANAGER'
    } else if(allRoles.includes('DATA_RECORDER')) {
      role = 'DATA_RECORDER'
    } else {
      role = 'VIEWER'
    }
  } else if(role == 'GUEST') {
    role = 'VIEWER'
  }

  return role
}

export const getMaxRolePerWorkingPoint = (userInfo) => {
  let maxRoles = {}
  let role = userInfo.role
  if(userInfo.permissions && role == 'GUEST') {
    userInfo.permissions.map(p => {
      p.workingPoints.map(wp => {
        maxRoles[wp] = p.role
      })
    })
  }
  return maxRoles
}
