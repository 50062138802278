import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    users: [],
    totalUsers: 0,
    userData: null
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_USERS(state, data) {
      state.users = data
    },
    SET_TOTAL_USERS(state, data) {
      state.totalUsers = data
    },
    SET_WORKING_POINTS(state, data) {
      state.workingPoints = data
    },
    SET_USER_DATA(state, data){
      state.userData = data
    }
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
   
    async savePayments({commit}, options) {
       let url = `${process.env.VUE_APP_API_URL}/auth/users/payments/${options.userId}`
       try {
        const res = await axiosInstance.post(
          url,
          options.payments,
          {
            withCredentials: true,
          },
        ); 
         if (res.status === 201) {
          commit('SET_USER_DATA', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUserPayments({commit}, id) {
      let url = `${process.env.VUE_APP_API_URL}/auth/users/payments/${id}`
      commit('SET_USER_DATA',null);
      try {
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        ); 
         if (res.status === 200) {
          commit('SET_USER_DATA', res.data);
          if(cb) {
            cb()
          }
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async fetchUsers({ commit }, options) {
      let page = options.page || 1
      let perPage = options.perPage || 25
      let search = options.search || ''
      let paymentFilter = options.paymentFilter || ''
       let statusFilter = options.statusFilter || ''
      let paymentStatusFilter = options.paymentStatusFilter || ''
      let cb = options.cb
      let filter = options.filter && options.filter != {} ? JSON.stringify(options.filter) : null
      try {
        let url = `${process.env.VUE_APP_API_URL}/user/forAdmin/?page=${page}&perPage=${perPage}`
        if (search && search.length) {
          url = `${url}&search=${search}`
        }
        if (paymentFilter && paymentFilter.length) {
          url = `${url}&paymentFilter=${paymentFilter}`
        }
        if (statusFilter && statusFilter.length) {
          url = `${url}&statusFilter=${statusFilter}`
        }
        if (paymentStatusFilter && paymentStatusFilter.length) {
          url = `${url}&paymentStatusFilter=${paymentStatusFilter}`
        }
        if (filter && filter.length) {
          url = `${url}&filter=${filter}`
        }
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_USERS', res.data.items);
          commit('SET_TOTAL_USERS', res.data.total);
          if(cb) {
            cb()
          }
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async exportUsers({ commit }, options) {
      let cb = options.cb
      let search = options.search || ''
      let filter = options.filter && options.filter != {} ? JSON.stringify(options.filter) : null
      let paymentFilter = options.paymentFilter || ''
       let statusFilter = options.statusFilter || ''
      let paymentStatusFilter = options.paymentStatusFilter || ''
      let queries = []
      if(search && search.length) {
        queries.push(`search=${search}`)
      }
      if(filter) {
        queries.push(`filter=${filter}`)
      }
      if(paymentFilter) {
        queries.push(`paymentFilter=${paymentFilter}`)
      }
      if(statusFilter) {
        queries.push(`statusFilter=${statusFilter}`)
      }
      if(paymentStatusFilter) {
        queries.push(`paymentStatusFilter=${paymentStatusFilter}`)
      }
      let queryString = ''
      if(queries.length) {
        queryString = `?${queries.join('&')}`
      }
      try {
        const res = await axiosInstance.get(
          `${process.env.VUE_APP_API_URL}/user/export/forAdmin${queryString}`,
          {
            withCredentials: true,
            responseType: 'blob'
          }
        );
        cb(res)
        // commit('SET_LOADING_R', false)
      } catch (error) {
        console.log(error)
        // commit('SET_LOADING_R', false)
        return this.error;
      }
    },
  },
};
