<template>
<auth-layout>
  <CRMSubjectLayout v-if="isAdmin" />
</auth-layout>
</template>
<script>
import AuthLayout from '../Layouts/AuthLayout.vue';
import CRMSubjectLayout from '../Layouts/CRMSubjectLayout.vue';
export default {
  data() {
    return {};
  },
  components: {
    AuthLayout,
    CRMSubjectLayout,
  },
  computed: {
    isAdmin() {
      return this.$store.state.auth.isAdmin
    },
  },
  watch: {
  },
  methods: {},
  created() {}
};
</script>
