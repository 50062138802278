<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Trimite proforma catre <b>{{ crm ? crm.email : "-" }}</b></span
          >
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-3" ref="form">
            <v-row>
              <v-text-field
                v-model="model.email"
                :rules="inputRules"
                persistent-placeholder
                label="Email"
                outlined
              />
            </v-row>

            <v-row class="py-1">
              <v-text-field
                v-model="model.subject"
                persistent-placeholder
                :rules="inputRules"
                label="Subject"
                outlined
              />
            </v-row>

            <v-row>
              <v-textarea
                rows="10"
                v-model="model.body"
                :label="'Text email'"
                outlined
              />
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()"
            >Trimite</v-btn
          >
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()"
            >Renunta</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
import toastr from "../../toastr";
export default {
  props: ["closeDialog", "crm", "getData"],
  data: () => ({
    dialog: true,
    inputRules: [(v) => !!v || "Campul este obligatoriu"],
    model: {},
    subject: "Factura proformă Registrul Deșeurilor",
    body: `Buna ziua,

Va anuntam ca a fost emisa factura proforma numarul \{\{numar_proforma\}\}.
‌
Factura poate fi vizualizata accesand link-ul:
\{\{link_proforma\}\}.

Va rugam sa ne transmiteti dovada platii ca reply la acest email.

Va multumim,
Echipa Registrul Deșeurilor
    `,
  }),
  watch: {},
  methods: {
    close() {
      this.closeDialog();
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (
          !this.crm.linkProforma ||
          !this.crm.seriesProforma ||
          !this.crm.noProforma
        ) {
          toastr.error("Nu există proformă generată pentru acest client");
          return;
        }

        if (!this.model.body || !this.model.body.length) {
          toastr.error("Nu există text pentru trimiterea proformei");
          return;
        }

        if (
          !this.model.body.includes("{{numar_proforma}}") ||
          !this.model.body.includes("{{link_proforma}}")
        ) {
          toastr.error(
            "Textul pentru e-mail trebuie sa contina variabilele {{numar_proforma}} si {{link_proforma}}"
          );
          return;
        }

        this.model.body = this.model.body.replace(
          "{{numar_proforma}}",
          `${this.crm.seriesProforma.toUpperCase()} - ${this.crm.noProforma}`
        );

        this.model.body = this.model.body.replace(
          "{{link_proforma}}",
          this.crm.linkProforma
        );

        this.$store.dispatch("sendProforma", {
          id: this.crm._id,
          model: { ...this.model },
          cb: () => {
            this.getData();
          },
        });
        this.closeDialog();
      }
    },
  },
  computed: {
    crmStatuses() {
      return this.$store.state.crm.crmStatuses.map((c) => c.status);
    },
  },
  created() {
    if (this.crm) {
      this.model = {
        email: this.crm.email,
        subject: this.subject,
        body: this.body,
      };
    }
    /**
     * if (editedItem) {
    model.value = {
      email: editedItem.email,
      subject: i18n.global.t("crm.proformaSubject"),
      body: i18n.global.t("crm.proformaText"),
    };
  }
     */
    // if (this.user) {
    //   this.$store.dispatch('fetchUserPayments', this.user._id)
    // }
  },
};
</script>
