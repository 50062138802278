<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Adauga observatie
            <b>{{ crm ? crm.email || crm.name : "" }}</b></span
          >
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form">
            <v-row>
              <v-select
                clearable
                label="Status *"
                v-model="model.status"
                density="compact"
                hide-details
                outlined
                :items="crmStatuses"
                color="primary"
                class="my-1"
                :rules="[(v) => !!v || 'Campul este obligatoriu']"
              >
              </v-select>
            </v-row>

            <v-row class="py-2">
              <v-text-field
                v-model="model.followUp"
                type="date"
                persistent-placeholder
                label="Data follow-up (optional)"
                outlined
                class="mt-2"
              />
            </v-row>

            <v-row>
              <v-textarea
                rows="4"
                v-model="model.comment"
                :label="'Comentariu'"
                outlined
                class="mt-1"
              />
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()"
            >Adauga</v-btn
          >
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()"
            >Renunta</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
export default {
  props: ["closeDialog", "crm", "getData"],
  data: () => ({
    dialog: true,
    inputRules: [(v) => !!v || "Campul este obligatoriu"],
    model: {
      followUp: null,
    },
  }),
  watch: {
    // crm() {
    //   this.$store.dispatch("fetchCRMLogs", this.crm._id);
    // },
  },
  methods: {
    close() {
      this.closeDialog();
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    save() {
      if (this.$refs.form.validate()) {
        // return
        this.model.crm = this.crm._id;
        this.$store.dispatch("createCRMLog", {
          model: { ...this.model },
          cb: () => {
            this.getData();
          },
        });
        this.closeDialog();
      }
    },
  },
  computed: {
    crmStatuses() {
      return this.$store.state.crm.crmStatuses.map((c) => c.status);
    },
  },
  created() {
    if (this.crm && this.crm.editedStatus) {
      this.model.status = this.crm.editedStatus;
    }
    // if (this.user) {
    //   this.$store.dispatch('fetchUserPayments', this.user._id)
    // }
  },
};
</script>
