var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin":"10px"}},[_c('section-headline',[_vm._v(" Statusuri user ")]),_c('AddButton',{attrs:{"onClick":function () { _vm.openCreateDialog() },"caption":"Adauga"}}),_c('v-data-table',{attrs:{"items":_vm.userStatuses,"headers":_vm.statusHeaders,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.userStatusesTotal,"footer-props":{
    itemsPerPageOptions: [25, 50, 100],
  }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(props){return [_c('p',{staticClass:"tableData"}),_c('div',{staticClass:"flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"turquoise"},on:{"click":function($event){return _vm.openCreateDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pen")])],1)]}}],null,true)},[_c('span',[_vm._v("Editeaza")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.openDeleteDialog(props.item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}}],null,true)},[_c('span',[_vm._v("Sterge")])])],1),_c('p')]}}],null,true)}),(_vm.createDialog)?_c('CreateUserStatus',{attrs:{"closeDialog":_vm.closeCreateDialog,"editedItem":_vm.currentStatus,"searchStatuses":_vm.searchUserStatuses}}):_vm._e(),(_vm.deleteDialog)?_c('DeleteDialog',{attrs:{"item":_vm.currentStatus,"itemName":"statusul","closeDialog":_vm.closeDeleteDialog,"deleteAction":_vm.deleteStatus}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }