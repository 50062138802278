import toastr from '../../toastr';
import axiosInstance from '../axiosWrapper';
export default {
  //===========================
  // STATE
  //===========================
  state: {
    workingPoints: [],
    totalWorkingPoints: 0,
    workingPointsData: [],
 
    firstWorkingPoint: null,
    loading: true
  },
  //===========================
  // Mutations.
  //===========================
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
    SET_WORKING_POINTS_DATA(state, data) {
      state.workingPointsData = data
    },
    DELETE_WORKING_POINT(state, id) {
      state.workingPoints = state.workingPoints.filter(wp => wp._id != id)
    },
    SET_WORKING_POINTS(state, data) {
      state.workingPoints = data
      state.firstWorkingPoint = data.filter(d => d.isDefault)[0]
      if(!state.firstWorkingPoint) {
        state.firstWorkingPoint = data[0]
      }
    },
    UPDATE_WORKING_POINT(state, workingPoint) {
      state.workingPointsData.map(company => {
        if (company._id != workingPoint.company._id) {
          return
        }
        company.workingPoints.map(wp => {
          if (wp._id != workingPoint._id) {
            return
          }
          let wasteCodesPerWorkingPoint = [...wp.wasteCodesPerWorkingPoint]
          wp = { ...workingPoint }
          wp.wasteCodesPerWorkingPoint = wasteCodesPerWorkingPoint
        })
      })
    }
  },
  //===========================
  // ACTIONS
  //===========================
  actions: {
    async createWorkingPoint({ commit }, formData) {
      let model = formData.data
      let cb = formData.cb
      let url = `${process.env.VUE_APP_API_URL}/workingPoints`
      try {
        const res = await axiosInstance.post(
          url,
          // `${process.env.VUE_APP_API_URL}/workingPoints/${userId}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 201) {
          toastr.success("Punctul de lucru a fost adaugat!")
          cb()
        }
      } catch (error) {
        toastr.error("Ai atins limita maxima de puncte de lucru")
        console.log(error)
      }
    },
    async fetchWorkingPointsData({ commit }, options) {
      let url = `${process.env.VUE_APP_API_URL}/companies/data`
      try {
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('SET_WORKING_POINTS_DATA', res.data);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateWorkingPointActive({ commit }, formData) {
      let workingPointId = formData.workingPointId
      // let model = formData.model
      // let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/workingPoints/switchActive/${workingPointId}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          // commit('UPDATE_WASTE_CODE_PWP', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      // cb()
    },
    async makeWorkingPointDefault({ commit }, formData) {
      let workingPointId = formData.workingPointId
      // let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/workingPoints/makeDefault/${workingPointId}`,
          {},
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          // commit('UPDATE_WASTE_CODE_PWP', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async updateWorkingPoint({ commit }, formData) {
      let id = formData.id
      let model = formData.model
      let cb = formData.cb
      try {
        const res = await axiosInstance.put(
          `${process.env.VUE_APP_API_URL}/workingPoints/${id}`,
          model,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('UPDATE_WORKING_POINT', res.data);
          toastr.success("Campul a fost updatat!")
        }
      } catch (error) {
        console.log(error)
        return this.error;
      }
      cb()
    },
    async fetchWorkingPoints({ commit }, options) {
       let url = `${process.env.VUE_APP_API_URL}/workingPoints`
      if(options && options.lean) {
        url = `${process.env.VUE_APP_API_URL}/workingPoints?lean=true`
      }
     
      try {
        commit('SET_LOADING', true);
        const res = await axiosInstance.get(
          url,
          {
            withCredentials: true,
          },
        );
      
        if (res.status === 200) {
          commit('SET_WORKING_POINTS', res.data);
          commit('SET_LOADING', false);
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
    async deleteWorkingPointById({ commit }, wpId) {
      let url = `${process.env.VUE_APP_API_URL}/workingPoints/${wpId}`
      // return
      try {
        const res = await axiosInstance.delete(
          url,
          {
            withCredentials: true,
          },
        );
        if (res.status === 200) {
          commit('DELETE_WORKING_POINT', wpId);
          toastr.success("Campul a fost sters!")
          return true;
        }
      } catch (error) {
        console.log(error)
      }
    },
  },
};
