<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline"
            >Istoric observatii
            <b>{{ crm ? crm.email || crm.name : "" }}</b></span
          >
        </v-card-title>

        <v-card-text>
          <v-data-table
            :items="crmLogs"
            :headers="headers"
            :items-per-page="10"
          >
            <template v-slot:[`item.createdAt`]="props">
              {{ parseDate(props.item.createdAt) }}
            </template>
            <template v-slot:[`item.createdBy`]="props">
              {{ props.item.createdBy ? props.item.createdBy.name : "" }}
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()"
            >Inchide</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
export default {
  props: ["closeDialog", "crm"],
  data: () => ({
    dialog: true,
    inputRules: [(v) => !!v || "Campul este obligatoriu"],
    headers: [
      { text: "Status", value: "status" },
      { text: "Comentariu", value: "comment" },
      { text: "Adaugat de", value: "createdBy" },
      { text: "Data adaugarii", value: "createdAt" },
    ],
  }),
  watch: {
    crm() {
      this.$store.dispatch("fetchCRMLogs", this.crm._id);
    },
  },
  methods: {
    close() {
      this.closeDialog();
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY hh:mm");
    },
  },
  computed: {
    crmLogs() {
      return this.$store.state.crm.crmLogs;
    },
  },
  created() {
    if (this.crm) {
      this.$store.dispatch("fetchCRMLogs", this.crm._id);
    }
  },
};
</script>
