<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px" @keydown.esc="close()">
      <v-card>
        <v-card-title>
          <span class="headline mb-2">{{editedItem ? 'Editeaza' : 'Adauga'}} status</span>
        </v-card-title>
        <v-card-text>
          <v-form class="w-full padded p-5" ref="form">
            <v-row v-if="!editedItem">
              <i class="ml-1">Pentru a adauga mai multe statusuri odata, separati statusurile prin "/". Exemplu : "Nu
                mai vrea/Va achita"</i>
            </v-row>
            <v-row>
              <div class="padded m-2 w-full">
                <v-text-field ref='focusMe' type="text" placeholder="Status *" v-model="model.status"
                  label="Status *" />
              </div>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Salveaza</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import {
  ListCategories
} from '../../common/ListCategories'
import AddButton from '../Buttons/AddButtonText.vue'
import moment from "moment-timezone";
export default {
  components: {
    AddButton
  },
  props: [
    "closeDialog",
    "editedItem",
    "searchStatuses"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},

  }),
  watch: {
  },
  methods: {
    close() {
      this.closeDialog()
    },

    save() {
      // return
      if (this.$refs.form.validate()) {
        if (this.model._id) {
          this.$store.dispatch('updateUserStatus', {
            model: this.model,
            cb: () => {
              this.searchStatuses()
            }
          });
        } else {
          if (this.model.status.includes('/')) {
            let statuses = this.model.status.split('/')
            statuses.forEach(status => {
              this.$store.dispatch('createUserStatus', {
                model: {
                  status
                },
                cb: () => {
                  this.searchStatuses()
                }
              });
            })
            // return
          } else {
            this.$store.dispatch('createUserStatus', {
              model: this.model,
              cb: () => {
                this.searchStatuses()
              }
            });
          }
       
        }

        this.close()
      }
    },

  },

  created() {
    this.$store.dispatch('fetchLists', {})
    if (this.editedItem) {
      this.model = {
        ...this.editedItem
      }
    }


  }
};
</script>
<style scoped>
@media only screen and (max-width: 600px) {

  .w-half,
  .w-third,
  .w-quarter,
  .w-75 {
    width: 100% !important
  }

}
</style>
