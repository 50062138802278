<template>
  <v-layout row justify="center">
    <v-dialog v-model="dialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="headline">Adauga observatie <b>{{ user ? user.email : '' }}</b></span>
        </v-card-title>
        <v-card-text>
        <v-form class="w-full padded p-5" ref="form">
          <v-row>
              <v-combobox
                clearable
                label="Status *"
                v-model="model.status"
                density="compact"
                hide-details
                outlined
                item-text="status"
                item-value="status"
                :items="userStatuses"
                color="primary"
                :return-object="false"
                class="my-1"
             
                :rules="[v => !!v || 'Campul este obligatoriu']"
              >
            </v-combobox>
          </v-row>

          <v-row>
            <v-textarea rows="4" v-model="model.comment" :label="'Comentariu'" outlined class="mt-2" />
           
          </v-row>
       
        </v-form>
      </v-card-text>
        <v-card-actions class="cardActions">
          <v-btn type="button" class="mainButtonYes" text @click="save()">Adauga</v-btn>
          <v-btn type="button" class="mainButtonNo" color="red" @click="close()">Renunta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script>
import moment from "moment-timezone";
export default {
  props: [
    "closeDialog",
    "user",
    "getData"
  ],
  data: () => ({
    dialog: true,
    inputRules: [v => !!v || "Campul este obligatoriu"],
    model: {},
  }),
  watch: {
    user() {
      this.$store.dispatch('fetchUserLogs', this.user._id)
    },
  },
  methods: {
    
   
    close() {
      this.closeDialog()
    },
    parseDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    save() {
      if (this.$refs.form.validate()) {
      
        // return
        this.model.user= this.user._id
        this.$store.dispatch('createUserLog', { model: {...this.model}, cb: () => {this.getData()}})
        this.closeDialog()
      }
    },
  },
  computed: {
  
    userStatuses() {
      return this.$store.state.userStatuses.userStatuses;
    }
  },
  created() {
    // if (this.user) {
    //   this.$store.dispatch('fetchUserPayments', this.user._id)
    // }
  }
};
</script>
