import axios from "axios";
import "vuetify/dist/vuetify.min.css";
import App from "./App.vue";
import "./assets/tailwind.css";
import router from "./router";
import store from "./store";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faCopy,
  faPen,
  faPlus,
  faSearch,
  faTrashCan,
  faUserSecret,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import VueQRCodeComponent from "vue-qrcode-component";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import Vuetify from "vuetify";
import "./registerServiceWorker";
/* add icons to the library */
library.add(faUserSecret);
library.add(faPen);
library.add(faXmark);
library.add(faSearch);
library.add(faTrashCan);
library.add(faCopy);
library.add(faPlus);
// Set default base url for axios.
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
Vue.directive("init", {
  bind: function (el, binding /*, vnode*/) {
    // console.log(binding.value); //# This line is optional, of course.
  },
});
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("qr-code", VueQRCodeComponent);
Vue.use(axios);
Vue.use(Vuetify, {
  theme: {
    options: {
      customProperties: true,
    },
  },
});
Vue.use(store);
Vue.use(VueTelInput);
Vue.use(() => {
  new Vue({
    el: "#app",
    vuetify: new Vuetify({
      theme: {
        dark: false,
        themes: {
          light: {
            primary: "#0d9cb4",
            accent: "#F7EB11",
            secondary: "#99E050",
            text: "#7A7A7A",
            mixed: "#30ad9b",
            turquoise: "#51bd83",
            lightBlue: "#e3ebfa",
            lightRed: "#e08794",
            red: "#D04A5E",
            /**
             * pastels for cards
             */
            lightestRed: "#ecb7be",
            lightestGreen: "#cbefa7",
            lightestYellow: "#fcf9b7",
            lightestBlue: "#9ed7e1",
            lightestTurquoise: "#a8ddc1",
          },
        },
      },
    }),
    components: {
      App,
    },
    router,
    store,
    render: (h) => h(App),
  });
});
